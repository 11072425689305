<template>
    <!-- 导航 -->
    <page-head title="球员数据分析" />
    <!-- 列表 -->
    <player-list :list="playerData.showList" @player-click="playerClick" />
    <!-- 翻页 -->
    <pagination-com class="pagination" :current-page="playerData.page" :total-page="playerData.totalPage" @changePage="changePage"></pagination-com>
</template>

<script>
import { useRouter, onBeforeRouteLeave } from "vue-router";
import {defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch, computed} from 'vue';
import { useStore } from "vuex";
export default defineComponent({
    setup() {
        const router = useRouter()
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const state = reactive({
            playerData: {}, // 页面数据
            serverData: {}, // 服务端数据
            query: {
                pageNum: 1,
                pageSize: 12
            },
        });
        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.playerData,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
                router: playerClick
            })
        })
        /** 获取球员列表 **/
        const getData = async () => {
            const { id } = store.state.teamData;
            const params = {
                teamId: id, // 球队id
            }
            const { code, data } = await proxy.$server.getPlayersList(params);
            if (code === 200) {
                const { pageNum, pageSize } = state.query;
                state.serverData = data;
                state.playerData = proxy.$utils.paginationFn(data, pageNum, pageSize);
            }
        };

        const changePage = (page) => {
            const { pageSize } = state.query;
            state.playerData = proxy.$utils.paginationFn(state.serverData, page, pageSize);
        }

        const playerClick = (row) => {
            let path = '/dataReport/playerAnalysis/details'

            router.push({
                path,
                query: {
                    id: row.id,
                    name: row.name
                }
            })
        }

        const queryParams = computed(() => {
            return store.state.queryParams;
        });

        onMounted(() => {
            if(queryParams.value.page) {
                state.query.pageNum = queryParams.value.page
            }
            getData()
        })
        onBeforeRouteLeave((to, from, next) => {
            console.log(to)
            let params = {
                page: state.query.pageNum
            }
            if(to.name === 'playerAnalysisDetails') {
                store.commit('setQueryParams', params);
            } else {
                params.page = 1
                store.commit('setQueryParams', params);
            }
            next()
        })

        return {
            ...toRefs(state),
            changePage,
            playerClick,
        }
    },
})
</script>

<style scoped lang="scss">
.pagination {
    margin-left: 590px;
}
</style>